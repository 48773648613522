export const FORM_CLASS_FOCUS = [
  'transition',
  'ease-in-out',
  'duration-200',
  'focus:ring-1',
  'focus:ring-gray-900',
  'hover:ring-gray-900',
  'focus-visible:ring-gray-900',
  'outline-none',
].join(' ');

export const FORM_CLASS_DISABLED = 'cursor-not-allowed pointer-events-none';

export const FORM_CLASS_INVALID = '!border-red-700';

export const FORM_CLASS_INPUT_BG = [
  'rounded-lg',
  'w-full',
  'border',
  'border-gray-300',
  'p-2.5',
].join(' ');

export const FORM_CLASS_INPUT_BG_ENABLED = [
  'text-gray-600',
  'hover:border-gray-700',
  'focus:ring-black',
  'focus:ring-0',
  'focus:border-black',
  'p-2.5',
].join(' ');

export const FORM_CLASS_INPUT_BG_DISABLED = 'bg-gray-100 ';

export const FORM_CLASS_INPUT_TEXT = 'text-sm';
export const FORM_CLASS_INPUT_TEXT_ENABLED = 'text-gray-800';
export const FORM_CLASS_INPUT_TEXT_DISABLED = 'text-gray-600 ';
